/** @jsx jsx */
import { jsx } from 'theme-ui'

import { PagePropsInterface } from '~/@types/models'

import PageBuilder from '~/componentsV2/pageBuilder'
import { IPageBuilderData } from '~/componentsV2/pageBuilder/types'

const Page = (props: PagePropsInterface<IPageBuilderData>) => {
  const data = props?.pageContext?.data

  return <PageBuilder path="pages" pageId={data.id} preloadedData={data} />
}

export default Page
